/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggle';
import FixedPanel from './FixedPanel';
import PageSection from './PageSection';
import Screener from './ScreenerNoLocation';
import Footer from './Footer';
import CookieBanner from './CookieBanner';
import { setLinks, splicer } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  const sitedata = {
    sitename: cfg.sitename,
    siteurl: cfg.siteurl,
    language: cfg.language,
    homelink: cfg.homelink,
    sections: cfg.sections,
    footer: cfg.footer,
    form: cfg.form
  };

  const {title, subtitle, startstudy, banner} = cfg;
  const sections = mergeSectionsWithImages(
    cfg.sections.map((section, index) => <PageSection key={section.id} config={section} />),
    [1,3]
  );

  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               theme={cfg.navbar.theme}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
          <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleLower="banner" visibleUpper="screener" />
          <Banner src={{title, subtitle, startstudy, banner}} />
        </header>
        <main>
          {sections}
          <Screener config={cfg.screener} sitedata={sitedata} localizerfn={localized} />
        </main>
        {props.children}
        <Footer src={footdata} localizerfn={localized} />
      </div>
      <CookieBanner />
    </div>
  );
};

function getImages() {
  const imgs = [
    makeDiv(<StaticImage src='../images/ctximg_1.jpg' alt="" className="mx-auto" />, 'img-1'),
    makeDiv(<StaticImage src='../images/ctximg_2.jpg' alt="" className="mx-auto" />, 'img-2')
  ];
  return function() { return imgs.shift(); }
}

function makeDiv(elem, id) { return <div className="-fluid" id={id} key={id}>{elem}</div>; }

function mergeSectionsWithImages(sections, imageIndexes) {
  if (!Array.isArray(imageIndexes)) return sections;
  const img = getImages();
  const spl = splicer(sections);
  imageIndexes.forEach(idx => { spl.at(img(), idx); });
  return spl.get();
}
